<template>
<b-row>
    <b-col>
      <b-row>
        <b-col>
          <h1>{{ $t('veranstalter_seite.meine_wettkämpfe.label_meine_wettkaempfe') }}</h1>
        </b-col>
        <b-col cols="auto" class="d-flex flex-row align-items-center">
          <b-button v-if="backToHomeBtn" class="mr-2" :to="`/${$i18n.locale}/veranstalter/uebersicht`">
            {{ $t('veranstalter_seite.label_zurueck') }}
          </b-button>
          <b-button v-if="backToEventsBtn" class="mr-2" @click="resetWettkampfListe">
            {{ $t('veranstalter_seite.label_zurueck') }}
          </b-button>
          <b-button @click="logoutUser">
            {{ $t('veranstalter_seite.label_abmelden') }}
          </b-button>
        </b-col>
      </b-row>
      <b-row v-if="user">
        <b-col>
          <WettkampfListe :user="user" :key="wettkampfListeKey" @displayBackBtn="displayBackBtn" @hideBackBtn="hideBackBtn"/>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import WettkampfListe from "./WettkampfListe";

export default {
  components: {
    WettkampfListe,
  },
  data() {
    return {
      backToHomeBtn: true,
      backToEventsBtn: false,
      wettkampfListeKey: 1, // Key wird verwendet um beim Component WettkampfListe einen Reload zu forcieren (Änderung des Keys forciert reload)
      user: false,
      backBtn: {
        display: false,
      },
    };
  },
  created() {
    this.getUserData();
  },
  methods: {
    getUserData() {
      this.user = this.$store.getters["veranstalter/getUser"];
    },
    logoutUser() {
      this.$store
        .dispatch("veranstalter/logout")
        .then(() => {
          console.log("Benutzer abgemeldet");
        })
        .catch((err) => {
          console.log(err);
        })
        .then(() => {
          this.$router.push(`/${this.$i18n.locale}/veranstalter/event-erstellen`);
        });
    },
    resetWettkampfListe() {
      this.hideBackBtn();
      this.wettkampfListeKey++;
    },
    displayBackBtn() {
      this.backToHomeBtn = false;
      this.backToEventsBtn = true;
    },
    hideBackBtn() {
      this.backToHomeBtn = true;
      this.backToEventsBtn = false;
    },
  },
};
</script>